<template>
  <PageWithLayout>
    <div class="inner_login" :style="cssProps">
      <h1 class="tit_login">
        <Logo size="100" />
        <span class="txt_logo">
          <!-- <span class="txt_service">{{ $commonData.service_name }}</span> -->
          <FirfinLogoText
            :logoColor="isDarkMode ? '#fff' : '#232323'"
            height="58"
          />
          <span class="txt_admin">{{
            $commonData.admin_name | wordToCamal(' ', ' ', 0)
          }}</span>
        </span>
      </h1>
      <form class="form_login">
        <template v-if="!viewModel.isOneStepDone">
          <Input
            placeholder="Email"
            size="large"
            :autocomplete="true"
            :valueType="viewModel.valid.email.valueType"
            :isError.sync="viewModel.valid.email.isValid"
            :errorDesc="viewModel.valid.email.validAlertDesc"
            :value.sync="viewModel.model.email"
            @onEnterKey="viewModel.onClickLogin()"
          />
          <Input
            placeholder="Password"
            size="large"
            type="password"
            :autocomplete="true"
            :valueType="viewModel.valid.password.valueType"
            :isError.sync="viewModel.valid.password.isValid"
            :errorDesc="viewModel.valid.password.validAlertDesc"
            :value.sync="viewModel.model.password_input"
            @onEnterKey="viewModel.onClickLogin()"
          />
        </template>
        <template v-else>
          <Input
            placeholder="MFA 코드"
            size="large"
            :autocomplete="true"
            :valueType="viewModel.validStep2.mfa.valueType"
            :isError.sync="viewModel.validStep2.mfa.isValid"
            :errorDesc="viewModel.validStep2.mfa.validAlertDesc"
            :value.sync="viewModel.model.mfa"
            @onEnterKey="viewModel.onClickLogin()"
          />
        </template>
        <Button
          btnSize="large"
          btnStyle="tertiary"
          text="LOGIN"
          :disabled="isValid"
          @onClickBtn="viewModel.onClickLogin()"
        />
      </form>
    </div>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Logo from '@/components/common/icon/Logo.vue';
import FirfinLogoText from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/FirfinLogoText.vue';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { mapGetters } from 'vuex';
import MediaQueryMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/MediaQueryMixin';
// viewModel
import LoginViewModel from '@/views/login/viewModel/LoginViewModel';

export default {
  name: 'Login',
  components: {
    PageWithLayout,
    Logo,
    FirfinLogoText,
    Input,
    Button,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      viewModel: new LoginViewModel(),
    };
  },
  computed: {
    ...mapGetters('passowrdChangeError', ['getIsPasswordChangeError']),
    isValid() {
      return this.viewModel.loginValidatedResult();
    },
    cssProps() {
      let rObj = {
        '--serviceLetterSpacing': this.$commonData.name_letter_spacing.login
          .service,
        '--adminLetterSpacing': this.$commonData.name_letter_spacing.login
          .admin,
      };
      return rObj;
    },
  },
  watch: {
    getIsPasswordChangeError() {
      if (this.getIsPasswordChangeError) {
        this.viewModel.resetLoginInfo();
      }
    },
  },
};
</script>

<style scoped>
/* .inner_login{padding:0 20px} */
.tit_login {
  overflow: hidden;
}
.tit_login .logo_lemontree {
  float: right;
}
/* .tit_login .logo_lemontree::v-deep .svg_logo{width:62px;height:62px} */

/* padding:10px 0 2px 10px; */
.tit_login .txt_logo {
  display: block;
  float: left;
  color: #232323;
  text-align: left;
}
.tit_login .txt_service {
  display: block;
  font-weight: 700;
  font-size: 52px;
  line-height: 58px;
  letter-spacing: var(--serviceLetterSpacing);
}
.tit_login .txt_admin {
  display: block;
  margin: 6px 0 0 2px;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: var(--adminLetterSpacing);
}

.form_login {
  margin: 8px auto 0;
}
.form_login .tf_comm + .tf_comm {
  margin-top: 8px;
}
.form_login .desc_login {
  padding: 0 0 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ccc;
  letter-spacing: -0.02em;
}
.form_login .btn_large {
  display: block;
  width: 100%;
  margin-top: 20px;
}

/* 모바일 */
@media all and (max-width: 440px) {
  .tit_login .logo_lemontree {
    float: none;
    margin: 0 auto;
  }
  .tit_login .txt_logo {
    float: none;
    margin-top: 20px;
    text-align: center;
  }
  .tit_login .logo_text_lemontree {
    margin: 0 auto;
  }
  .tit_login .txt_service {
    font-size: 42px;
    line-height: 44px;
  }
  .form_login {
    margin-top: 40px;
    padding: 0 20px;
  }
}
@media (prefers-color-scheme: dark) {
  .tit_login .txt_logo {
    color: #fff;
  }
}
</style>
