export default class LoginModel {
  constructor(){
    this.email = '';
    this.password_input = '';
    this.key = '';
    this.password = '';
    this.mfa = '';
    this.loginToken = '';
  }
  getData(){
    let obj = {
      email : this.email,
      password : this.password,
    }
    return obj;
  }
  getMfaData(){
    let obj = {
      mfa : this.mfa,
    }
    return obj;
  }
}